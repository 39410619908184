import { createApp } from "vue";
import App from "./App.vue";
import Webhumsafar from "./Webhumsafar.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import store from "./store";
import lanObj from "./json/lan.json"
import Shoesandsandles from "./Shoesandsandles.vue";
import Ads1Webhumsafar from "./ads1.Webhumsafar.vue";
import AdsShoesandsandles from "./ads.Shoesandsandles.vue";
import Tataonline from "./ads.Tataonline.vue";
import Tataonline1 from "./ads1.Tataonline.vue";

loadFonts();
let main = ''
if(window.location.hostname.includes('ads1.webhumsafar.online')) {
    main = Ads1Webhumsafar
}else if(window.location.hostname.includes('webhumsafar.online')) {
    main = Webhumsafar
} else if(window.location.hostname.includes('ads.tataonline.co')) {
    main = Tataonline
}   else if(window.location.hostname.includes('ads1.tataonline.co')) {
    main = Tataonline1
}   else if(window.location.hostname.includes('tataonline.co')) {
    main = App
}  else if(window.location.hostname.includes('tataonline.club')) {
    main = App
} else if(window.location.hostname.includes('tataonline.info')) {
    main = App
} else if(window.location.hostname.includes('tataonline.art')) {
    main = App
} else if (window.location.hostname.includes('ads.shoesandsandles.in')) {
    main = AdsShoesandsandles
} else if (window.location.hostname.includes('shoesandsandles.in')) {
    main = Shoesandsandles
}
const app = createApp(main)

app.use(store)
app.config.globalProperties.$wa = (flag=1, redirect='n') => {
    // Retrieve data from localStorage
const storedData = localStorage.getItem("apiResponse");

if (storedData) {
  const parsedData = JSON.parse(storedData); // Convert back to JSON
  const whatsappNumber = parsedData.whatsapp_number; // Extract the field
  const whatsapp_number1 = parsedData.whatsapp_number1; // Extract the field
  const msg = parsedData.msg; // Extract the field
  if(redirect == 'n') {
    return flag==2?whatsapp_number1:whatsappNumber
  }
  if (whatsappNumber) {
    const message = encodeURIComponent(msg);
    const whatsappURL = `whatsapp://send?phone=${flag==2?whatsapp_number1:whatsappNumber}&text=${message}`;
    
    // Open WhatsApp in a new tab
    window.open(whatsappURL, "_blank");
  } else {
    console.error("WhatsApp number not found in API response.");
  }
} else {
  console.error("No data found in localStorage.");
}

}
const fetchDetail = () => {
    fetch("https://admin.tataonline.co/api/v1/info.php")
  .then(response => response.json()) // Parse the response as JSON
  .then(data => {
    localStorage.setItem("apiResponse", JSON.stringify(data.data[0])); // Store in localStorage
    console.log("Data saved:", data); // Log for debugging
  })
  .catch(error => console.error("Error fetching data:", error)); // Handle errors

}
fetchDetail();
app.config.globalProperties.$lan = (key) => {
    try {
        let { lan } = app.config.globalProperties.$store.state
        if (lanObj[lan][key]) {
            return lanObj[lan][key]
        }
    } catch (err) {
        console.error(err);
        return ''
    }
}

app.use(vuetify)
app.mount("#app");





