<template>
  <v-row justify="center" class="contact-us" id="contactus">
    <v-col lg="3" md="3" sm="3" class="mx-5 contact-div">
      <div class="m-get-in-touch-d1">
        <div class="wlc f-drop-shadow">
          <div class="text-shadow-red lh-normal">
            <span>{{ $lan(47) }} </span>
            <span>{{ $lan(48) }}</span>
          </div>
        </div>
        <div class="text-shadow-red">
          {{ $lan(49) }}
        </div>
      </div>
    </v-col>
    <v-col lg="3" md="3" sm="3" class="mx-5 contact-div contact-bg c-col-2">
      <div class="m-get-in-touch-d2">
        <div>
          {{ $lan(50) }}
        </div>
        <v-btn   prepend-icon="mdi-whatsapp"  size="x-large" class="whtbtn" @click="$wa(1,'y')">+{{$wa(1)}}</v-btn>
        <v-btn   prepend-icon="mdi-whatsapp"  size="x-large" class="whtbtn" @click="$wa(2,'y')">+{{$wa(2)}}</v-btn>
                 
      </div>
    </v-col>
    <v-col lg="3" md="3" sm="3" class="mx-5 contact-div contact-bg c-col-3">
      <div class="m-get-in-touch-d3">
        <div>
          {{ $lan(51) }}
        </div>
        <div>{{ $lan(52) }}</div>
        <div @click="$wa(1,'y')">
          {{ $lan(53) }}
        </div>
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" class="nav-bar-home">
      <span class="nav-logo">
        <v-img src="../assets/logo.gif"></v-img>
      </span>
      <span class="nav-item" @click="setActiveTab('home')" :class="getCurrentTab == 'home' ? 'selected' : ''">{{ $lan(12)
        }}</span>
      <span class="nav-item" @click="setActiveTab('services')" :class="getCurrentTab == 'services' ? 'selected' : ''">{{
        $lan(8) }}</span>
      <span class="nav-item" @click="setActiveTab('plateforms')"
        :class="getCurrentTab == 'plateforms' ? 'selected' : ''">{{ $lan(4) }}</span>
      <span class="nav-item" @click="setActiveTab('aboutus')" :class="getCurrentTab == 'aboutus' ? 'selected' : ''">{{
        $lan(9) }}</span>
      <span class="nav-item" @click="setActiveTab('sportid')" :class="getCurrentTab == 'sportid' ? 'selected' : ''">{{
        $lan(10) }}</span>
      <span class="nav-item" @click="setActiveTab('contactus')" :class="getCurrentTab == 'contactus' ? 'selected' : ''">{{
        $lan(11) }}</span>

    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  computed: {
    ...mapGetters(['getCurrentTab', ''])
  },
  methods: {
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) { }
    },
    setActiveTab(value) {
      this.scroll(value)
      this.toggle = ''
      this.$store.commit('setCurrentTab', value)
    },
    scroll(element) {
      var ele = document.getElementById(element);
      window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }
  },
};
</script>
<style scoped>
.selected {
  color: #EFAD19;
  height: 100%;
  border-bottom: 5px solid #EFAD19;
  align-content: center;
}

.nav-select {
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  background-color: transparent;
  padding: 10px 20px;
  border: none;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../assets/home/arrow.svg") right no-repeat;
}

.nav-select:focus {
  border: none;
  outline: none;
}

option:hover {
  background-color: yellow;
}

.nav-lang {
  display: flex;
  align-content: center;
}

.nav-lang-icon {
  width: 34px;
  height: 34px;
}

.nav-logo {
  width: 238px;
}

.nav-bar-home {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 200px;
}

.nav-item {
  font-size: 17px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.contact-us {
  background: url("../assets/contactus/bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 685px;
  align-content: center;
}

.contact-div {
  height: 301px;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-bg {
  background: #3c3c3e;
}

.text-design-w {
  color: #fff;
  -webkit-text-stroke: 1px #f5ba37;
  text-shadow: -1px -1px 0 #f5ba37, 1px -1px 0 #f5ba37, -1px 1px 0 #f5ba37,
    1px 1px 0 #f20808;
}

.text-design-y {
  color: #feef85;
  -webkit-text-stroke: 1px #f5ba37;
  text-shadow: -1px -1px 0 #f5ba37, 1px -1px 0 #f5ba37, -1px 1px 0 #f5ba37,
    1px 1px 0 #f5ba37;
}

.social-media {
  display: flex;
}

.sm-child {
  width: 35px;
  height: 35px;
}

.c-col-2 {
  /* background: url("../assets/contactus/whatapp.svg"); */
  /* background-repeat: no-repeat;
  background-size: contain; */
  text-align: center;
}

.c-col-3 {
  /* background: url("../assets/contactus/steptostep.svg"); */
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 768px) {
  .contact-div {
    height: 160px;
  }

  .contact-us {
    height: 300px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .contact-div {
    height: 210px;
  }

  .contact-us {
    height: 400px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .contact-div {
    height: 210px;
  }

  .contact-us {
    height: 620px;
  }
}
.m-get-in-touch-d3 {
  border-radius: 25px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.m-get-in-touch-d3 div:nth-child(1) {
    font-size: 22px;
  }

  .m-get-in-touch-d3 div:nth-child(2) {
    font-size: 15px;
  }

  .m-get-in-touch-d3 div:nth-child(3) {
    font-size: 15px;
  }
  .m-get-in-touch-d3 div:nth-child(3) {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  background: rgb(254, 216, 27);
  background: linear-gradient(90deg, rgba(254, 216, 27, 1) 0%, rgba(252, 166, 18, 1) 100%);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}
</style>
